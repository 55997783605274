import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { baseUrl } from "./statics/url";
import PublicRoute from "./routes/publicRoutes";
import AuthRoute from "./routes/authenticatedRoutes";
import ProtectedRoute from "./routes/protectedRoutes";

import routes from "./routes/routes";

import "./app.css";

function App() {
  const getRouteType = ({ element, type }) => {
    const routeType = {
      authenticated: <AuthRoute>{element}</AuthRoute>,
      protected: <ProtectedRoute>{element}</ProtectedRoute>,
      public: <PublicRoute>{element}</PublicRoute>,
    };

    return routeType[type];
  };

  const onIdle = () => {
    const handleLogout = async () => {
      const token = sessionStorage.getItem("token");
      toast.success("Logging out");

      try {
        const request = await fetch(`${baseUrl}/logout`, {
          method: "POST",
          headers: {
            Authorization: `bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
          }),
        });

        const response = await request.json();
        if (response.success) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("user");

          window.location.replace("https://beta.gazettes.ng");
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleLogout();
  };

  useIdleTimer({ onIdle, timeout: 1000 * 60 * 30 });

  return (
    <div className="app">
      <main>
        <Routes>
          {routes.map(({ element, type, path }, idx) => {
            return (
              <>
                <Route
                  path={path}
                  key={idx}
                  exact
                  element={getRouteType({ element, type })}
                />
              </>
            );
          })}
        </Routes>
      </main>
    </div>
  );
}

export default App;
