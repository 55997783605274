import { useState, useEffect } from "react";
import styles from "./styles.module.scss";

import SearchIconGrey from "../../assets/search_icon_grey.svg";
import SearchIconWhite from "../../assets/search_icon_white.svg";

export default function Search() {
  const [searchTerm, setSearchTerm] = useState("");

  const search = () => {
    window.location.replace(`/search?q=${searchTerm}&type=keywords`);
  };

  const checkKeyPress = (e) => {
    if (e.which === 13)
      return window.location.replace(`/search?q=${searchTerm}&type=keywords`);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.q && setSearchTerm(params.q);
  }, []);

  return (
    <div className={styles.search}>
      <div className={styles.search_component}>
        <div className={styles.search_input}>
          <img src={SearchIconGrey} alt="Search Icon" />
          <input
            type="text"
            placeholder="Search by title, keyword or year"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => checkKeyPress(e)}
          />
          <button onClick={search}>
            <img src={SearchIconWhite} alt="Search Icon" />
          </button>
        </div>

        <div className={styles.search_trigger}>
          {/* <button>ADVANCED SEARCH</button> */}
        </div>
      </div>
    </div>
  );
}
