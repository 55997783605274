import Signin from "./signin";
import Document from "./documents";
import NewDocument from "./new";
import Admins from "./admins";
import Gazette from "./gazette";

import Users from "./users";
import Categories from "./categories";
import Leaders from "./leaders";
import Dashboard from "./dashboard";
import Tags from "./tags";
import Search from "./search";
import Profile from "./profile";
import Activity from "./activity";

const pages = {
  Signin,
  Document,
  NewDocument,
  Admins,
  Users,
  Categories,
  Leaders,
  Dashboard,
  Tags,
  Search,
  Profile,
  Gazette,
  Activity,
};

export default pages;
