import React from "react";
import ReactDOM from "react-dom";

import { ToastContainer } from "react-toastify";

import "./index.css";
import App from "./app";

import { UserProvider } from "./contexts/user";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <UserProvider>
    <React.StrictMode>
      <Router>
        <App />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Router>
    </React.StrictMode>
  </UserProvider>,
  document.getElementById("root")
);
