const sidebarLinks = [
  {
    name: "Dashboard",
    link: "",
  },
  {
    name: "Documents",
    link: "documents",
  },
  {
    name: "Admins",
    link: "admins",
    role: "SuperAdmin",
  },
  {
    name: "Users",
    link: "users",
    role: "SuperAdmin",
  },
  {
    name: "Categories",
    link: "categories",
    role: "SuperAdmin",
  },
  // {
  //   name: "Tags",
  //   link: "tags",
  // },
  {
    name: "Activities",
    link: "activities",
    role: "SuperAdmin",
  },
  {
    name: "Presidents",
    link: "leaders",
  },
  {
    name: "My Account",
    link: "my-account",
  },
];

export default sidebarLinks;
