import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

import Logo from "../../assets/Logo.png";

import Search from "../search";

// import { SearchModal } from "../../contexts/search_modal";

export default function Navbar() {
  return (
    <div className={styles.navbar}>
      <div className={styles.navbar_logo}>
        <Link to="/">
          <img src={Logo} alt="Nigerian Gazette Logo" />
        </Link>
      </div>

      <Search />

      <div className={`${styles.navbar_search} ${styles.new}`}>
        <button>
          <Link to="/new">NEW GAZETTE</Link>
        </button>
      </div>

      <div className={styles.navbar_search}>
        <button>
          <Link to="/my-account">MY ACCOUNT</Link>
        </button>
      </div>
    </div>
  );
}
