import { useEffect, useState, useCallback } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import ReactPaginate from "react-paginate";

import styles from "./styles.module.scss";
import Loader from "../../components/loader";
import Close from "../../assets/close.svg";

import { Request } from "../../helpers/requests";

import { baseUrl } from "../../statics/url";

import prev_icon from "../../assets/prev_page.svg";
import next_icon from "../../assets/next_page.svg";

const nextLabel = <img src={next_icon} alt="next" />;
const prevLabel = <img src={prev_icon} alt="prev" />;

export default function Admins() {
  const [admins, setAdmins] = useState(null);
  const [addAdmin, setAddAdmin] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);

  const [adminPermissions, setAdminPermissions] = useState(null);

  const fetchData = useCallback(async () => {
    setAdmins(null);

    try {
      const token = sessionStorage.getItem("token");

      const config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const request = await fetch(
        `${baseUrl}/admin-users?page=${page}`,
        config
      );
      const response = await request.json();

      if (response.data?.length > 0) {
        setAdmins(response.data);
        setPageCount(response.meta.last_page);
      }
    } catch (error) {
      return { error };
    }
  }, [page]);

  useEffect(() => {
    fetchData();
    const requestPermissions = new Request("permissions");
    requestPermissions.fetchData(setAdminPermissions);
  }, [fetchData]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const Delete = async (item) => {
    const token = sessionStorage.getItem("token");
    console.log(item);

    confirmAlert({
      title: `Delete ${item.first_name} ${item.last_name}?`,
      message: "Are you sure you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setAdmins(null);
            const response = await fetch(`${baseUrl}/delete-user/${item.id}`, {
              method: "DELETE",
              headers: {
                Authorization: `bearer ${token}`,
              },
            });

            await response.json();
            const request = new Request("admin-users");
            request.fetchData(setAdmins);
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  const Deactivate = async (item) => {
    const token = sessionStorage.getItem("token");

    confirmAlert({
      title: `Deactivate ${item.first_name} ${item.last_name}?`,
      message: "Are you sure you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setAdmins(null);
            const response = await fetch(
              `${baseUrl}/deactivate-account/${item.id}`,
              {
                headers: {
                  Authorization: `bearer ${token}`,
                },
              }
            );

            await response.json();
            const request = new Request("admin-users");
            request.fetchData(setAdmins);
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  const Activate = async (item) => {
    const token = sessionStorage.getItem("token");

    confirmAlert({
      title: `Activate ${item.first_name} ${item.last_name}?`,
      message: "Are you sure you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setAdmins(null);
            const response = await fetch(
              `${baseUrl}/activate-account/${item.id}`,
              {
                // method: "DELETE",
                headers: {
                  Authorization: `bearer ${token}`,
                },
              }
            );

            await response.json();
            const request = new Request("admin-users");
            request.fetchData(setAdmins);
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  return (
    <div className={styles.documents}>
      {addAdmin && (
        <AddAdminModal
          setAddAdmin={setAddAdmin}
          setAdmins={setAdmins}
          admins={admins}
          adminPermissions={adminPermissions}
        />
      )}

      {selectedAdmin && (
        <EditAdminModal
          setAdmins={setAdmins}
          selectedAdmin={selectedAdmin}
          setSelectedAdmin={setSelectedAdmin}
          adminPermissions={adminPermissions}
        />
      )}

      <div className={styles.documentsHeader}>
        <h3>ADMINS</h3>
        <button onClick={() => setAddAdmin(true)}>ADD ADMIN</button>
      </div>

      {!admins && <Loader />}

      {admins && (
        <div className={styles.documentsContainer}>
          {admins.length === 0 && <span>No records found</span>}
          {admins.length > 0 && (
            <div className={styles.documentsContainerTable}>
              <table>
                <tr>
                  <th>NO</th>
                  <th>ADMINs</th>
                  <th>TYPE</th>
                  <th>DATE JOINED</th>
                  <th>STATUS</th>
                  <th>ACTIONS</th>
                </tr>

                {admins.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>
                        {`${item.first_name} ${item.last_name}`} <br />{" "}
                        {item.email}
                      </td>
                      <td>{item.role?.name}</td>
                      <td>{dayjs(item.created_at).format("DD MMMM, YYYY")}</td>
                      <td>{item.is_active === 1 ? "Active" : "Deactivated"}</td>
                      <td>
                        <button
                          style={{ padding: 6, fontSize: 12 }}
                          onClick={() => setSelectedAdmin(item)}
                        >
                          EDIT
                        </button>
                        {item.is_active === 1 && (
                          <button onClick={() => Deactivate(item)}>
                            DEACTIVATE
                          </button>
                        )}
                        {item.is_active === 0 && (
                          <button
                            className={styles.activate}
                            onClick={() => Activate(item)}
                          >
                            REACTIVATE
                          </button>
                        )}

                        <button onClick={() => Delete(item)}>DELETE</button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
        </div>
      )}

      {pageCount && (
        <div
          className={styles.page_pagination}
          style={{ visibility: !admins ? "hidden" : "initial" }}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel={nextLabel}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={prevLabel}
            renderOnZeroPageCount={null}
            activeClassName={styles.active_page}
          />
        </div>
      )}
    </div>
  );
}

export function AddAdminModal({
  admins,
  setAddAdmin,
  setAdmins,
  adminPermissions,
}) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [savingData, setSavingData] = useState(false);

  const saveAdmin = async () => {
    if (
      !firstname ||
      !lastname ||
      !email ||
      !password ||
      selectedPermissions.length < 1
    ) {
      return toast.warn("All fields are required");
    }

    try {
      setSavingData(true);
      const token = sessionStorage.getItem("token");

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstname,
          last_name: lastname,
          email,
          password,
          password_confirmation: password,
          role_id: 2,
          permission_id: selectedPermissions,
        }),
      };

      const request = await fetch(`${baseUrl}/create-admin`, config);

      const response = await request.json();

      console.log(response.data);
      setSavingData(false);

      if (response.success) {
        setAdmins([response.data, ...admins]);
        setAddAdmin(false);
        return;
      }

      toast.error(response.message);
    } catch (error) {
      console.log(error);
      setSavingData(false);
    }
  };

  const handlePermission = (p) => {
    if (selectedPermissions.includes(p.id)) {
      const index = selectedPermissions.indexOf(p.id);
      setSelectedPermissions([
        ...selectedPermissions.splice(0, index),
        ...selectedPermissions.splice(index + 1),
      ]);
      return;
    }

    setSelectedPermissions([...selectedPermissions, p.id]);
  };

  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>ADD ADMIN</h4>
          <img
            src={Close}
            alt="Close Modal"
            onClick={() => setAddAdmin(false)}
          />
        </div>

        <div className={styles.share_modal_input}>
          <label>First name</label>
          <input
            type="text"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />

          <label>Last name</label>
          <input
            type="text"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />

          <label>Email</label>
          <input
            type="text"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />

          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <label htmlFor="">Permissions</label>
          {adminPermissions.map((p, idx) => {
            if (p.name === "Delete") return null;

            return (
              <div key={idx} className={styles.permissions}>
                <input
                  onClick={() => handlePermission(p)}
                  type="checkbox"
                  name={p.name}
                  value={p.id}
                />
                <label for={p.name}>{p.name}</label>
              </div>
            );
          })}

          <div>
            {!savingData ? (
              <button onClick={saveAdmin}>Save</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Saving</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function EditAdminModal({
  selectedAdmin,
  setSelectedAdmin,
  setAdmins,
  adminPermissions,
}) {
  const [firstname, setFirstname] = useState(selectedAdmin.first_name);
  const [lastname, setLastname] = useState(selectedAdmin.last_name);

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [savingData, setSavingData] = useState(false);

  console.log(selectedAdmin);

  const saveAdmin = async () => {
    try {
      setSavingData(true);
      const token = sessionStorage.getItem("token");

      const config = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstname,
          last_name: lastname,
          permission_id: selectedPermissions,
        }),
      };

      const request = await fetch(
        `${baseUrl}/update-profile/${selectedAdmin.id}`,
        config
      );

      const response = await request.json();

      setSavingData(false);

      setAdmins(null);
      const refreshRequest = new Request("admin-users");
      refreshRequest.fetchData(setAdmins);

      if (response.success) {
        console.log(response);
        setSelectedAdmin(null);
        return;
      }
    } catch (error) {
      console.log(error);
      setSavingData(false);
    }
  };

  useEffect(() => {
    const arr = [];

    selectedAdmin.permissions.map((item) => {
      return arr.push(item.id);
    });

    setSelectedPermissions(arr);
  }, [selectedAdmin]);

  const handlePermission = (p) => {
    if (selectedPermissions.includes(p.id)) {
      const index = selectedPermissions.indexOf(p.id);
      setSelectedPermissions([
        ...selectedPermissions.splice(0, index),
        ...selectedPermissions.splice(index + 1),
      ]);
      return;
    }

    setSelectedPermissions([...selectedPermissions, p.id]);
  };

  const getDefaultCheck = (id) => {
    const bool = selectedPermissions.includes(id);

    return bool;
  };

  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>EDIT ADMIN</h4>
          <img
            src={Close}
            alt="Close Modal"
            onClick={() => setSelectedAdmin(null)}
          />
        </div>

        <div className={styles.share_modal_input}>
          <label>First name</label>
          <input
            type="text"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />

          <label>Last name</label>
          <input
            type="text"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />

          <label htmlFor="">Permissions</label>
          {adminPermissions.map((p, idx) => {
            if (p.name === "Delete") return null;

            return (
              <div key={idx} className={styles.permissions}>
                <input
                  onChange={() => handlePermission(p)}
                  type="checkbox"
                  name={p.name}
                  value={p.id}
                  checked={getDefaultCheck(p.id)}
                />
                <label for={p.name}>{p.name}</label>
              </div>
            );
          })}
          {selectedAdmin.role?.name === "SuperAdmin" &&
            adminPermissions.map((p, idx) => {
              if (p.name === "Delete")
                return (
                  <div key={idx} className={styles.permissions}>
                    <input
                      onChange={() => handlePermission(p)}
                      type="checkbox"
                      name={p.name}
                      value={p.id}
                      checked={getDefaultCheck(p.id)}
                    />
                    <label for={p.name}>{p.name}</label>
                  </div>
                );
              return null;
            })}

          <div>
            {!savingData ? (
              <button onClick={saveAdmin}>Save</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Saving</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
