import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import TagBox from "../../components/tag";
import Loader from "../../components/loader";

import fileIcon from "../../assets/file-icon.png";
import fileIconUploaded from "../../assets/file-icon-uploaded.png";

import styles from "./styles.module.scss";
import { baseUrl } from "../../statics/url";
import { loadPDF } from "../../helpers/embedPdf";

export default function NewDocument() {
  const [tab, setTab] = useState("");
  const [slug, setSlug] = useState("");
  const [data, setData] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [completedTabs, setCompletedTabs] = useState(["upload"]);

  const [nxtActive, setNxtActive] = useState(false);

  const [published, setPublished] = useState(false);
  const [publishing, setPublishing] = useState(false);

  const status = [
    {
      name: "upload file",
      tab: "upload",
    },
    {
      name: "text extraction",
      tab: "extract",
    },
    {
      name: "transcribe",
      tab: "transcribe",
    },
    {
      name: "edit",
      tab: "edit",
    },
    {
      name: "publish",
      tab: "publish",
    },
  ];

  useEffect(() => {
    if (!tab) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      params.slug && setSlug(params.slug);

      setTab("upload");

      if (params.tab) {
        const tabs = {
          0: "extract",
          1: "extract",
          2: "transcribe",
          3: "edit",
          4: "publish",
        };

        const nextTabs = {
          upload: "extract",
          extract: "transcribe",
          transcribe: "edit",
          edit: "publish",
          publish: "publish",
        };

        let values = Object.values(nextTabs);
        const keys = Object.keys(nextTabs);

        const index = keys.indexOf(params.tab);
        let valuesNew = values.slice(0, index);

        setCompletedTabs([...valuesNew]);
        return setTab(tabs[params.tab]);
      }
    }
  }, [tab]);

  useEffect(() => {
    (async () => {
      setNxtActive(false);
      try {
        const token = sessionStorage.getItem("token");

        const config = {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        };

        const request = await fetch(`${baseUrl}/gazettes/${slug}`, config);
        const response = await request.json();

        console.log(response);

        if (response.data) {
          setData(response.data);
        }
      } catch (error) {
        console.warn(error);
      }
    })();
  }, [slug]);

  const setNextTab = () => {
    if (!nxtActive) return;

    const nextTabs = {
      upload: "extract",
      extract: "transcribe",
      transcribe: "edit",
      edit: "publish",
      publish: "publish",
    };

    setCompletedTabs([...completedTabs, tab]);
    setTab(nextTabs[tab]);
  };

  const publishData = async () => {
    toast.success("Publishing gazette");
    try {
      setPublishing(true);
      const token = sessionStorage.getItem("token");

      const config = {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      };

      const request = await fetch(
        `${baseUrl}/gazettes-publish/${data.id}`,
        config
      );
      const response = await request.json();

      if (response.success) {
        setPublished(true);
        toast.success("Gazette has been published");
        return;
      }

      toast.error(response.message);
    } catch (error) {
      toast.warn("An error occurred please try again later");
    }
  };

  return (
    <div className={styles.new}>
      <div className={styles.newDocumentsHeader}>
        <h3>UPLOAD A DOCUMENT</h3>
        {tab !== "upload" && (
          <button
            onClick={tab === "publish" ? publishData : setNextTab}
            style={{ opacity: !nxtActive && 0.5 }}
          >
            {tab === "publish" && publishing
              ? "PUBLISHING"
              : tab === "publish"
              ? "PUBLISH"
              : "NEXT"}
          </button>
        )}
      </div>

      <div className={styles.main}>
        {showModal && <PublishModal setShowModal={setShowModal} />}
        <div className={styles.statusContainer}>
          {status.map((item, index) => {
            return (
              <div
                key={index}
                className={styles.status}
                onClick={() => {
                  if (!completedTabs.includes(item.tab)) return;
                  setTab(item.tab);
                }}
                style={{
                  color:
                    tab === item.tab
                      ? "green"
                      : !completedTabs.includes(item.tab)
                      ? "#c4c4c4"
                      : "#000",
                  cursor: "pointer",
                }}
              >
                <h3>{index + 1}</h3>
                <span
                  style={{
                    fontWeight: tab === item.tab && "800",
                    textDecoration: tab === item.tab && "underline",
                  }}
                >
                  {item.name.toUpperCase()}
                </span>
              </div>
            );
          })}
        </div>

        <div className={styles.eventDiv}>
          {tab === "upload" && (
            <UploadContainer setSlug={setSlug} setTab={setTab} />
          )}
          {tab === "extract" && (
            <ExtractionContainer
              slug={slug}
              setNxtActive={setNxtActive}
              data={data}
              setData={setData}
            />
          )}
          {tab === "transcribe" && data && (
            <TranscribeContainer
              slug={slug}
              data={data}
              setNxtActive={setNxtActive}
            />
          )}
          {tab === "edit" && data && (
            <EditContainer
              data={data}
              setData={setData}
              setNxtActive={setNxtActive}
            />
          )}
          {tab === "publish" && data && (
            <PublishContainer
              data={data}
              setData={setData}
              setNxtActive={setNxtActive}
            />
          )}
          {published && <PublishModal />}
        </div>
      </div>
    </div>
  );
}

export const UploadContainer = ({ setTab, setSlug }) => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [uploading, setUploading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  useEffect(() => {
    if (!file || !title) return setIsFileUploaded(false);
    if (title.length < 3) return setIsFileUploaded(false);

    setIsFileUploaded(true);
  }, [file, title]);

  const handleDrop = (event) => {
    event.preventDefault();

    if (event.dataTransfer.items) {
      if (event.dataTransfer.items[0].kind === "file") {
        var file = event.dataTransfer.items[0].getAsFile();
        setFile(file);
      }
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDragOver = (event) => {
    setIsDraggingOver(true);
    event.preventDefault();
  };

  const nextTab = async () => {
    if (!isFileUploaded) return;
    setUploading(true);

    try {
      const token = sessionStorage.getItem("token");
      const formData = new FormData();

      formData.append("title", title);
      formData.append("file_path", file);

      const config = {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      };

      const request = await fetch(`${baseUrl}/gazettes`, config);
      console.log(request);
      const response = await request.json();

      setUploading(false);

      if (response.success) {
        setSlug(response.data.slug);
        setTab("extract");
        toast.success("File Uploaded");
        return;
      }

      toast.error(response.message);
    } catch (error) {
      toast.warn("An error occurred please try again later");
      setUploading(false);
      console.warn(error);
    }
  };

  return (
    <div className={styles.fileUploadContainer}>
      <div className={styles.title}>
        <span>Title:</span>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Please input your file title"
        />
      </div>

      <div
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        onDrop={(e) => handleDrop(e)}
        className={styles.fileUpload}
        style={{ borderColor: isDraggingOver && "green" }}
      >
        <div className={styles.imgIcon}>
          <img src={file ? fileIconUploaded : fileIcon} alt="File icon" />
          <p>{file ? "File Added" : "Drag and drop files here or"}</p>
        </div>

        <div className={styles.actionBtn}>
          <label>
            Browse Files
            <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          </label>
        </div>

        {file && (
          <div className={styles.fileName}>
            <span>{file.name}</span>
          </div>
        )}

        <div className={styles.uploadButton}>
          {!uploading ? (
            <button
              onClick={nextTab}
              style={{ opacity: !isFileUploaded && 0.5 }}
            >
              UPLOAD
            </button>
          ) : (
            <button style={{ opacity: 0.5 }}>UPLOADING...</button>
          )}
        </div>
      </div>
    </div>
  );
};

export const ExtractionContainer = ({ slug, data, setData, setNxtActive }) => {
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    (async () => {
      setNxtActive(false);
      try {
        const token = sessionStorage.getItem("token");

        const config = {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        };

        const request = await fetch(`${baseUrl}/gazettes/${slug}`, config);
        const response = await request.json();

        console.log(response);

        if (response.data) {
          setData(response.data);
        }

        setFetchingData(false);
        setNxtActive(true);
      } catch (error) {
        toast.warn("An error occurred please try again later");
        console.warn(error);
      }
    })();
  }, [slug, setData, setNxtActive]);

  if (fetchingData) return <Loader />;

  return (
    <div className={styles.extraction}>
      <div className={styles.extractionHeader}>
        <span>FILE INFO</span>
      </div>

      <div className={styles.fileInfo}>
        <h5>File Name</h5>
        <span>{data.title}</span>
      </div>

      {/* <div className={styles.fileInfo}>
        <h5>File Size</h5>
        <span></span>
      </div> */}

      <div className={styles.fileInfo}>
        <h5>File Format</h5>
        <span>PDF</span>
      </div>

      <div className={styles.fileInfo}>
        <h5>Status</h5>
        <span>{data.stage_message}</span>
      </div>
    </div>
  );
};

export const TranscribeContainer = ({ data, setNxtActive }) => {
  const [saving, setSaving] = useState(false);
  const [summary, setSummary] = useState(data.abstract);

  const [ocrCheck, setOcrCheck] = useState(false);

  useEffect(() => {
    setNxtActive(false);

    if (!data.id) {
      return;
    }

    (async () => {
      try {
        const token = sessionStorage.getItem("token");

        const config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const request = await fetch(
          `${baseUrl}/ocr-refresh-gazettes/${data.id}`,
          config
        );
        const response = await request.json();

        console.log(response);

        if (response.success) {
          setOcrCheck(true);
        } else {
          toast.error(
            "Text extraction has not yet been completed, Please try again in 3 minutes"
          );
          <Navigate to="/documents" />;
        }
      } catch (error) {
        toast.warn("An error occurred please try again later");
        console.warn(error);
      }
    })();
  }, [setNxtActive, data]);

  const saveSummary = async () => {
    (async () => {
      try {
        setSaving(true);
        const token = sessionStorage.getItem("token");

        const config = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            gazette_id: data.id,
            abstract: summary.endsWith(".") ? summary : `${summary}.`,
          }),
        };

        const request = await fetch(`${baseUrl}/transcribe-gazette`, config);
        const response = await request.json();

        if (response.success) {
          toast.success("Transcribed Successfully");
          setNxtActive(true);
        } else {
          toast.error(response.message);
        }

        setSaving(false);
      } catch (error) {
        toast.warn("An error occurred please try again later");
        console.warn(error);
      }
    })();
  };

  if (!ocrCheck) return <Loader />;

  return (
    <div className={styles.gazette}>
      <div className={styles.gazette_document}>
        <div className={styles.gazette_document_tabs}>
          <div>
            <p>Original PDF</p>
          </div>
        </div>

        <div
          className={styles.gazette_file}
          id="pdf-div"
          onDocumentLoad={loadPDF({ url: data.file_url, id: "pdf-div" })}
        ></div>
      </div>

      <div className={styles.gazette_transcribing}>
        <div>
          <h5>Enter the Summary of the Gazette</h5>
        </div>

        <div className={styles.gazette_transcribingField}>
          <textarea
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          ></textarea>
        </div>

        <div className={styles.gazette_transcribingBtns}>
          <button onClick={() => setSummary("")}>CLEAR</button>
          {!saving ? (
            <button onClick={saveSummary}>SAVE</button>
          ) : (
            <button style={{ opacity: 0.5 }} onClick={saveSummary}>
              SAVING...
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const EditContainer = ({ data, setData, setNxtActive }) => {
  const [title, setTitle] = useState(data.title);
  const [year, setYear] = useState(data.published_date);
  const [assentYear, setAssentYear] = useState(data.ascent_date);
  const [volume, setVolume] = useState(data.volume);
  const [currentDate, setCurrentDate] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategory, setselectedCategory] = useState(
    data.category?.id || null
  );
  const [selectedPresident, setselectedPresident] = useState(
    data.president?.id || null
  );

  const [updateReady, setUpdateReady] = useState(false);
  const [saving, setSaving] = useState(false);

  const [tags, setTags] = useState(null);
  const [presidents, setPresidents] = useState(null);
  const [categories, setCategories] = useState(null);
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    const yyyy = today.getFullYear();

    if (dd < 10) dd = "0" + dd;

    if (mm < 10) mm = "0" + mm;

    today = yyyy + "-" + mm + "-" + dd;

    setCurrentDate(today);
  }, []);

  useEffect(() => {
    setNxtActive(false);
    (async () => {
      try {
        const token = sessionStorage.getItem("token");

        const config = {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        };

        const request = await fetch(`${baseUrl}/presidents`, config);
        const response = await request.json();

        if (!response.errors) {
          setPresidents(response.data);
        }
      } catch (error) {
        console.warn(error);
      }
    })();

    (async () => {
      try {
        const token = sessionStorage.getItem("token");

        const config = {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        };

        const request = await fetch(`${baseUrl}/tags`, config);
        const response = await request.json();

        if (response.data) {
          setTags(response.data);
          return;
        }

        if (!response.success) {
          setTags([]);
          return;
        }
      } catch (error) {
        console.warn(error);
      }
    })();

    (async () => {
      try {
        const token = sessionStorage.getItem("token");

        const config = {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        };

        const request = await fetch(`${baseUrl}/categories`, config);
        const response = await request.json();

        if (!response.errors) setCategories(response.data);
      } catch (error) {
        console.warn(error);
      }
    })();
  }, [setNxtActive]);

  useEffect(() => {
    if (!categories || !presidents || !tags) return;

    setFetchingData(false);
  }, [categories, presidents, tags]);

  useEffect(() => {
    if (!selectedCategory || !selectedPresident || !title || !year || !volume) {
      setUpdateReady(false);
      setNxtActive(false);
      return;
    }

    setUpdateReady(true);
  }, [selectedCategory, selectedPresident, title, year, volume, setNxtActive]);

  const handleUpdate = async () => {
    try {
      setSaving(true);
      const token = sessionStorage.getItem("token");

      // const specTags = tags.filter((tag) => selectedTags.includes(tag.name));
      // const tagIds = specTags.map((tag) => tag.id);

      const config = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
          president_id: selectedPresident,
          category_id: selectedCategory,
          published_date: year,
          ascent_date: assentYear,
          // tags: tagIds,
          volume,
        }),
      };

      const request = await fetch(`${baseUrl}/gazettes/${data.id}`, config);
      const response = await request.json();

      if (response.success) {
        setData(response.data);
        setNxtActive(true);
        toast.success("Gazette edited");
      } else {
        toast.success("Gazette edited");
      }

      setSaving(false);
    } catch (error) {
      toast.warn("An error occurred please try again later");
      console.warn(error);
    }
  };

  if (fetchingData) return <Loader />;

  return (
    <div className={styles.editContainer}>
      <div className={styles.editContainerField}>
        <label>Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className={styles.editContainerField}>
        <label>Category</label>
        <select
          value={selectedCategory}
          onChange={(e) => setselectedCategory(e.target.value)}
        >
          <option value="">Select a Category</option>
          {categories.map((item, index) => (
            <option key={index} value={item.id}>
              {item.title}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.editContainerField}>
        <label>Date of Assent</label>
        <input
          type="date"
          value={assentYear}
          min="1999-01-01"
          max={currentDate}
          onChange={(e) => {
            setAssentYear(e.target.value);
          }}
          placeholder="MM/DD/YYYY"
        />
      </div>

      <div className={styles.editContainerField}>
        <label>Publication Date</label>
        <input
          type="date"
          value={year}
          min="1999-01-01"
          max={currentDate}
          onChange={(e) => {
            setYear(e.target.value);
          }}
        />
      </div>

      <div className={styles.editContainerField}>
        <label>Presidents</label>
        <select
          value={selectedPresident}
          onChange={(e) => setselectedPresident(e.target.value)}
        >
          <option value="">Select a President</option>
          {presidents.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.editContainerField}>
        <label>Volume no.</label>
        <input
          type="text"
          value={volume}
          onChange={(e) => setVolume(e.target.value)}
        />
      </div>

      {/* <div className={styles.editContainerField}>
        <label>Tags</label>
        <TagBox
          tags={tags}
          setTags={setTags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </div> */}

      <div className={styles.editContainerBtn}>
        {updateReady && !saving ? (
          <button onClick={handleUpdate}>UPDATE</button>
        ) : (
          <button style={{ opacity: 0.5 }}>
            {saving ? "UPDATING..." : "UPDATE"}
          </button>
        )}
      </div>
    </div>
  );
};

export const PublishContainer = ({ data, setData, setNxtActive }) => {
  //   const [fetchPDF, setFetchPDF] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  let path = data.slug;

  useEffect(() => {
    (async () => {
      setNxtActive(false);
      try {
        const token = sessionStorage.getItem("token");

        const config = {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        };

        const request = await fetch(`${baseUrl}/gazettes/${path}`, config);
        const response = await request.json();

        if (response.data) {
          setData(response.data);
        }

        setFetchingData(false);
        setNxtActive(true);
      } catch (error) {
        toast.warn("An error occurred please try again later");
        console.warn(error);
      }
    })();
  }, [setNxtActive, setData, path]);

  if (fetchingData) return <Loader />;

  return (
    <div className={styles.publish}>
      <div className={styles.gazette_info}>
        <h4>{data.title}</h4>
        {/* <span className={styles.gazette_category}>
          {data.category && data.category.title}
        </span> */}

        <span className={styles.gazette_date}>
          Publication Date {data.published_date && data.published_date}
        </span>

        <span className={styles.gazette_senate}>
          Signed by {data.president && data.president.name}
        </span>

        {/* <div className={styles.gazette_tags}>
          <div className={styles.tags}>
            <span>Tags: </span>
            {data.tags?.map((tag) => {
              return <span className={styles.tag_box}>{tag.name}</span>;
            })}
          </div>
        </div> */}

        <div className={styles.gazette_summary}>
          <h5>Publication Summary</h5>
          <p>{data.abstract}</p>
        </div>

        <audio controls autoplay>
          <source src={data.polly_url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>

      <div className={styles.gazette_document}>
        <div className={styles.gazette_document_tabs}>
          <div>
            <p>Original PDF</p>
          </div>
        </div>

        <div
          className={styles.gazette_file}
          id="pdf-div"
          onDocumentLoad={loadPDF({ url: data.file_url, id: "pdf-div" })}
        ></div>
      </div>
    </div>
  );
};

export function PublishModal() {
  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>PUBLISH GAZETTE</h4>
        </div>

        <div className={styles.share_modal_input}>
          <div className={styles.share_modal_input}>
            <span>Gazette has been Published</span>

            <span>
              Back to <Link to="/documents">documents</Link>{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
