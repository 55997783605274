import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { confirmAlert } from "react-confirm-alert"; // Impor
import "react-confirm-alert/src/react-confirm-alert.css";

import styles from "./styles.module.scss";
import Loader from "../../components/loader";
import Close from "../../assets/close.svg";

import { Request } from "../../helpers/requests";
import { User } from "../../contexts/user";

import { baseUrl } from "../../statics/url";
import { toast } from "react-toastify";

export default function Tags() {
  const { user } = User();
  const [tags, setTags] = useState(null);
  const [addTag, setAddTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    const request = new Request("tags");
    request.fetchData(setTags);
  }, []);

  const Delete = async (item) => {
    const token = sessionStorage.getItem("token");

    confirmAlert({
      title: `Delete ${item.name} tag`,
      message:
        "Are you sure you want to proceed? Once deleted, it cannot be restored",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setTags(null);
            const response = await fetch(`${baseUrl}/tags/${item.id}`, {
              method: "DELETE",
              headers: {
                Authorization: `bearer ${token}`,
              },
            });

            const data = await response.json();

            if (!data.success) {
              toast.warning(
                "You don't have permission to carry out this action"
              );
            }

            const request = new Request("tags");
            request.fetchData(setTags);
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  return (
    <div className={styles.documents}>
      {addTag && (
        <AddTagModal setAddTag={setAddTag} setTags={setTags} tags={tags} />
      )}

      {selectedTag && (
        <EditTagModal
          setTags={setTags}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />
      )}

      <div className={styles.documentsHeader}>
        <h3>TAGS</h3>
        <button onClick={() => setAddTag(true)}>CREATE TAG</button>
      </div>

      {!tags && <Loader />}

      {tags && (
        <div className={styles.documentsContainer}>
          {tags.length === 0 && <span>No records found</span>}
          {tags.length > 0 && (
            <div className={styles.documentsContainerTable}>
              <table>
                <tr>
                  <th>NO</th>
                  <th>NAME</th>
                  <th>DATE CREATED</th>
                  <th>ACTIONS</th>
                </tr>

                {tags.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>
                        {item.name} <br />
                      </td>
                      <td>{dayjs(item.created_at).format("DD MMMM, YYYY")}</td>
                      <td>
                        <button onClick={() => setSelectedTag(item)}>
                          EDIT
                        </button>
                        {user.role.name === "SuperAdmin" && (
                          <button onClick={() => Delete(item)}>DELETE</button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function AddTagModal({ tags, setAddTag, setTags }) {
  const [name, setName] = useState("");

  const [savingData, setSavingData] = useState(false);

  const saveTag = async () => {
    try {
      setSavingData(true);
      const token = sessionStorage.getItem("token");

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
        }),
      };

      const request = await fetch(`${baseUrl}/tags`, config);

      const response = await request.json();
      setSavingData(false);

      if (response.success) {
        setTags([response.data, ...tags]);
        setAddTag(false);
        return;
      } else {
        toast.warning("You don't have permission to carry out this action");
      }
    } catch (error) {
      console.log(error);
      setSavingData(false);
    }
  };

  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>CREATE TAG</h4>
          <img
            src={Close}
            alt="Close Modal"
            onClick={() => {
              setAddTag(false);
            }}
          />
        </div>

        <div className={styles.share_modal_input}>
          <label>Tag name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <div>
            {!savingData ? (
              <button onClick={saveTag}>Save</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Saving</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function EditTagModal({ setTags, selectedTag, setSelectedTag }) {
  const [name, setName] = useState(selectedTag.name);

  const [savingData, setSavingData] = useState(false);

  const saveTag = async () => {
    try {
      setSavingData(true);
      const token = sessionStorage.getItem("token");

      const config = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
        }),
      };

      const request = await fetch(`${baseUrl}/tags/${selectedTag.id}`, config);

      const response = await request.json();
      setSavingData(false);

      if (response.success) {
        setSelectedTag(null);

        setTags(null);
        const request = new Request("tags");
        request.fetchData(setTags);
        return;
      } else {
        toast.warning("You don't have permission to carry out this action");
      }
    } catch (error) {
      console.log(error);
      setSavingData(false);
    }
  };

  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>EDIT TAG</h4>
          <img
            src={Close}
            alt="Close Modal"
            onClick={() => setSelectedTag(null)}
          />
        </div>

        <div className={styles.share_modal_input}>
          <label>Tag name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <div>
            {!savingData ? (
              <button onClick={saveTag}>Save</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Saving</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
