import { confirmAlert } from "react-confirm-alert"; // Import
import { baseUrl } from "../statics/url";

export class Request {
  constructor(path) {
    this.path = `${baseUrl}/${path}`;
  }

  async login(email, password) {
    const config = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        password,
      }),
    };

    try {
      const request = await fetch(this.path, config);
      const response = await request.json();

      console.log(response);

      if (!response.success) {
        return {
          status: false,
          ...response,
        };
      }

      const userString = JSON.stringify(response.token.original.user);

      sessionStorage.setItem("token", response.token.original.access_token);
      sessionStorage.setItem("user", userString);

      return {
        status: true,
        ...response,
      };
    } catch (error) {
      return {
        status: false,
        error,
      };
    }
  }

  async fetchData(setter) {
    try {
      const token = sessionStorage.getItem("token");

      const config = {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      };

      const request = await fetch(this.path, config);

      const response = await request.json();

      if (setter) {
        if (response.data) return setter(response.data);
        setter([]);
      }

      return response;
    } catch (error) {
      return { error };
    }
  }

  async deleteData({ title, id, setter }) {
    const token = sessionStorage.getItem("token");

    if (!title || !id) return console.warn("title, path and id are required");

    const globRequest = confirmAlert({
      title,
      message:
        "Are you sure you want to proceed? Once deleted, it cannot be restored",
      buttons: [
        {
          label: "YES",
          onClick: async () => {
            const request = await fetch(`${this.path}/${id}`, {
              method: "DELETE",
              headers: { Authorization: `bearer ${token}` },
            });

            if (setter) setter(null);

            const response = await request.json();

            return response;
          },
        },
        {
          label: "NO",
          onClick: () => {
            return null;
          },
        },
      ],
    });

    if (globRequest?.success) {
      return {
        status: true,
      };
    }
    return { status: false };
  }
}
