import { useEffect, useState } from "react";
import Loader from "../../components/loader";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

import Close from "../../assets/close.svg";
import { User } from "../../contexts/user";

import { Request } from "../../helpers/requests";
import { baseUrl } from "../../statics/url";

import styles from "./styles.module.scss";
import { toast } from "react-toastify";

export default function Leaders() {
  const { user } = User();
  const [leaders, setLeaders] = useState(null);
  const [addLeaders, setAddLeaders] = useState(false);

  const [selectedLeaders, setselectedLeaders] = useState(null);

  useEffect(() => {
    const request = new Request("presidents");
    request.fetchData(setLeaders);
  }, []);

  const Delete = async (item) => {
    const token = sessionStorage.getItem("token");

    confirmAlert({
      title: `Delete President ${item.name}?`,
      message:
        "Are you sure you want to proceed? Once deleted, it cannot be restored",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setLeaders(null);

            const response = await fetch(`${baseUrl}/presidents/${item.id}`, {
              method: "DELETE",
              headers: {
                Authorization: `bearer ${token}`,
              },
            });

            await response.json();
            const request = new Request("presidents");
            request.fetchData(setLeaders);
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  return (
    <div className={styles.documents}>
      {addLeaders && (
        <AddLeaderModal
          setAddLeaders={setAddLeaders}
          setLeaders={setLeaders}
          leaders={leaders}
        />
      )}

      {selectedLeaders && (
        <EditLeaderModal
          setLeaders={setLeaders}
          selectedLeaders={selectedLeaders}
          setselectedLeaders={setselectedLeaders}
        />
      )}

      <div className={styles.documentsHeader}>
        <h3>PRESIDENTS OF NIGERIA</h3>
        <button onClick={() => setAddLeaders(true)}>ADD PRESIDENT</button>
      </div>

      {!leaders && <Loader />}

      {leaders && (
        <div className={styles.documentsContainer}>
          {leaders.length === 0 && <span>No records found</span>}
          {leaders.length > 0 && (
            <div className={styles.documentsContainerTable}>
              <table>
                <tr>
                  <th>NO</th>
                  <th>PRESIDENT NAME</th>
                  <th>ACTIONS</th>
                </tr>

                {leaders.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{item.name}</td>
                      <td>
                        <button onClick={() => setselectedLeaders(item)}>
                          EDIT
                        </button>{" "}
                        {user.role.name === "SuperAdmin" && (
                          <button onClick={() => Delete(item)}>DELETE</button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function AddLeaderModal({ leaders, setAddLeaders, setLeaders }) {
  const [title, setTitle] = useState("");

  const [savingData, setSavingData] = useState(false);

  const saveAdmin = async () => {
    try {
      setSavingData(true);
      const token = sessionStorage.getItem("token");

      const formData = new FormData();
      formData.append("name", title);

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      };

      const request = await fetch(`${baseUrl}/presidents`, config);

      const response = await request.json();

      console.log(response.data);
      setSavingData(false);

      if (response.success) {
        setLeaders([response.data, ...leaders]);
        setAddLeaders(false);
        return;
      } else {
        toast.warning("You don't have permission to carry out this action");
      }
    } catch (error) {
      console.log(error);
      setSavingData(false);
    }
  };

  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>ADD PRESIDENT</h4>
          <img
            src={Close}
            alt="Close Modal"
            onClick={() => setAddLeaders(false)}
          />
        </div>

        <div className={styles.share_modal_input}>
          <label>President name</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <div>
            {!savingData ? (
              <button onClick={saveAdmin}>Save</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Saving</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function EditLeaderModal({
  setLeaders,
  selectedLeaders,
  setselectedLeaders,
}) {
  const [title, setTitle] = useState(selectedLeaders.name);

  const [savingData, setSavingData] = useState(false);

  const saveAdmin = async () => {
    try {
      setSavingData(true);
      const token = sessionStorage.getItem("token");

      const config = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: title,
        }),
      };

      const request = await fetch(
        `${baseUrl}/presidents/${selectedLeaders.id}`,
        config
      );

      const response = await request.json();

      console.log(response);
      setSavingData(false);

      if (response.success) {
        setselectedLeaders(null);

        setLeaders(null);
        const request = new Request("presidents");
        request.fetchData(setLeaders);
        return;
      } else {
        toast.warning("You don't have permission to carry out this action");
      }
    } catch (error) {
      console.log(error);
      setSavingData(false);
    }
  };

  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>EDIT PRESIDENT</h4>
          <img
            src={Close}
            alt="Close Modal"
            onClick={() => setselectedLeaders(null)}
          />
        </div>

        <div className={styles.share_modal_input}>
          <label>President</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <div>
            {!savingData ? (
              <button onClick={saveAdmin}>Save</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Saving</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
