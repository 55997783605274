import { useState, useEffect } from "react";

import { User } from "../../contexts/user";
import { Request } from "../../helpers/requests";
import ReactGA from "react-ga";

import Logo from "../../assets/Logo.png";
import password_open from "../../assets/password_open.svg";
import password_close from "../../assets/password_close.svg";

import styles from "./styles.module.scss";

export default function Signin() {
  const [state, setState] = useState("login");
  const [action, setAction] = useState(null);
  const [page, setPage] = useState(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.action ? setAction(params.action) : setAction("");
    params.page ? setPage(params.page) : setPage("");

    ReactGA.initialize("UA-215525316-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className={styles.signin}>
      <main>
        <div className={styles.logo_landing}>
          <a href="/">
            <img src={Logo} alt="Website Logo" />
          </a>
          <p>
            Welcome to the Official Gazette Publication of the Federal Republic
            of Nigeria from 1999 till date. This is a collaboration between the
            National Institute for Legislative Studies & Konrad Adenauer
            Stiftung and it is accurate, legitimate, valid and in accordance
            with the Constitution of the Federal Republic of Nigeria in order to
            bring the law closer to the people.
          </p>
        </div>
        <div>
          {state === "login" && (
            <LoginForm setState={setState} page={page} action={action} />
          )}
        </div>
      </main>
    </div>
  );
}

export const LoginForm = ({ action, page }) => {
  const { setUser } = User();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  const [passwordState, setPasswordState] = useState("password");

  const login = async () => {
    setProcessing(true);

    const request = new Request("login");
    const login = await request.login(email, password);

    if (!login.status) {
      if (login.error) {
        return setError(
          "An error occurred while trying to sign to sign you in, please try again later."
        );
      }
      setError(login.message || "");
      return setProcessing(false);
    }

    setUser(login.token.original.user);

    if (action) {
      window.location.replace(`${page}?action=${action}`);
      return;
    }
    window.location.replace(`/`);
  };

  return (
    <div className={styles.login}>
      <h3>LOGIN</h3>

      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <span className={styles.form_error}>{error}</span>
          <label htmlFor="">Enter your Email</label>
          <input
            type="text"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <br />

          <label htmlFor="">Enter your Password</label>
          <div className={styles.form_login_box}>
            <input
              type={passwordState}
              placeholder="**************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.form_login_box_input}
            />
            {passwordState === "password" && (
              <img
                src={password_close}
                alt="show password"
                onClick={() => setPasswordState("text")}
              />
            )}
            {passwordState === "text" && (
              <img
                src={password_open}
                alt="hide password"
                onClick={() => setPasswordState("password")}
              />
            )}
          </div>

          {!processing ? (
            <button
              className={styles.form_submit}
              type="submit"
              onClick={login}
            >
              Login
            </button>
          ) : (
            <span className={styles.form_processing}>Signing In...</span>
          )}
        </form>
      </div>
    </div>
  );
};
