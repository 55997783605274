import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import dayjs from "dayjs";
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactPaginate from "react-paginate";

import Table, { TableBody, TableRow } from "../../components/table";
// import { Request } from "../../helpers/requests";
import { baseUrl } from "../../statics/url";
import Loader from "../../components/loader";

import { User } from "../../contexts/user";
import styles from "./styles.module.scss";

import prev_icon from "../../assets/prev_page.svg";
import next_icon from "../../assets/next_page.svg";

const nextLabel = <img src={next_icon} alt="next" />;
const prevLabel = <img src={prev_icon} alt="prev" />;

export default function Documents() {
  const { user } = User();
  const [documents, setDocuments] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [filterType, setFilterType] = useState(null);
  const [filteredDocuments, setFilteredDocuments] = useState(null);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [showCount, setShowCount] = useState(20);
  const [sortBy, setSortBy] = useState("desc");
  const [status, setStatus] = useState("");

  const filterDocuments = useCallback(() => {
    if (!documents || !filterValue) {
      setFilteredDocuments(documents);
      return;
    }

    const filteredDocs = documents.filter((doc) => {
      if (
        doc.stage_message.toLowerCase() === filterValue.toLowerCase() ||
        `${doc.user?.first_name.toLowerCase()} ${doc.user?.last_name.toLowerCase()}` ===
          filterValue.toLowerCase() ||
        doc.year.toString() === filterValue
      ) {
        return true;
      } else {
        return false;
      }
    });

    setFilteredDocuments(filteredDocs);
  }, [documents, filterValue]);

  const fetchData = useCallback(async () => {
    setDocuments(null);
    try {
      const token = sessionStorage.getItem("token");

      // let cbPage = 1;

      // if (lastPage !== page) {
      //   cbPage = page;
      // }

      setLastPage(page);

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_by: sortBy,
          page_number: parseInt(showCount),
          status,
        }),
      };

      const request = await fetch(
        `${baseUrl}/get-gazettes?page=${page}`,
        config
      );
      const response = await request.json();

      if (response.data) {
        setDocuments(response.data);
        setPageCount(response.meta.last_page);
      }
    } catch (error) {
      return { error };
    }
  }, [sortBy, showCount, page, status]);

  useEffect(() => {
    fetchData();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.filter && setFilterValue(params.filter);
    params.type && setFilterType(params.type);
  }, [fetchData]);

  useEffect(() => {
    filterDocuments();
  }, [filterValue, filterDocuments, documents]);

  const columnStyles = [
    {
      width: "10%",
      justifyContent: "center",
    },
    {
      width: "35%",
    },
    {
      width: "15%",
    },
    {
      width: "15%",
    },
    {
      width: "15%",
      color: "blue",
      textDecoration: "underline",
    },
    {
      width: "10%",
      justifyContent: "center",
    },
  ];

  const Delete = async (item) => {
    const token = sessionStorage.getItem("token");

    if (user.role.name !== "SuperAdmin") {
      return toast.warning("You don't have permissions to perform this action");
    }

    confirmAlert({
      title: `Delete ${item.title}?`,
      message:
        "Are you sure you want to proceed? Once deleted, it cannot be restored",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setDocuments(null);

            await fetch(`${baseUrl}/gazettes/${item.id}`, {
              method: "DELETE",
              headers: {
                Authorization: `bearer ${token}`,
              },
            });

            // await response.json();
            fetchData();
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  const tableRowData = (item, index) => {
    return [
      { text: `${index + 1 + (page - 1) * showCount}` },
      {
        text: <Link to={`/gazettes/${item.slug}`}>{item.title}</Link>,
      },
      {
        text: item.ascent_date
          ? `${dayjs(item.ascent_date).format("DD MMMM, YYYY")}`
          : " ",
      },
      {
        text: `${dayjs(item.created_at).format("DD MMMM, YYYY")}`,
      },
      // {
      //   text:
      //     item.stage !== 5 ? (
      //       <Link to={`/new?tab=${item.stage}&slug=${item.slug}`}>
      //         {item.stage_message}
      //       </Link>
      //     ) : (
      //       <span>{item.stage_message}</span>
      //     ),
      // },
      {
        text: (
          <Link to={`/new?tab=${item.stage}&slug=${item.slug}`}>
            {item.stage_message}
          </Link>
        ),
      },
      {
        text: <AiOutlineDelete color="red" size={20} />,
        styles: { cursor: "pointer" },
        onClick: () => Delete(item),
      },
    ];
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  return (
    <div className={styles.documents}>
      <div className={styles.documentsHeader}>
        <h3>DOCUMENTS</h3>
        {/* <Link to="/new">
          <button>New Gazette</button>
        </Link> */}
      </div>

      {!filteredDocuments && <Loader />}

      {filteredDocuments && (
        <>
          <div
            // style={{ backgroundColor: "red" }}
            className={styles.profile_pagination}
          >
            {/* <input
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              type="text"
              placeholder="Search by title, keyword or year"
            /> */}

            <section>
              <div>
                <span>Sort by Status: </span>
                <select
                  name=""
                  id=""
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">All</option>
                  {/* <option value="0">Uploaded</option> */}
                  <option value="1">Moved to text extraction</option>
                  <option value="2">Text Extracted</option>
                  <option value="3">Transcribed</option>
                  <option value="4">Edited</option>
                  <option value="5">Published</option>
                </select>
              </div>

              <div>
                <span>Sort by Date: </span>
                <select
                  name=""
                  id=""
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="desc">Newest to oldest</option>
                  <option value="asc">Oldest to newest</option>
                </select>
              </div>

              <div>
                <span>Show:</span>
                <select
                  name=""
                  id=""
                  value={showCount}
                  onChange={(e) => setShowCount(e.target.value)}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </section>
          </div>

          {filterType && (
            <div style={{ background: "#fff", padding: "10px 50px" }}>
              <i>
                <b>
                  <span>
                    Showing results:{" "}
                    {filterType === "year"
                      ? "Uploaded Year"
                      : filterType === "uploaded"
                      ? "Uploaded By"
                      : filterType === "status"
                      ? "Status"
                      : ""}
                  </span>
                </b>
              </i>
            </div>
          )}

          <div className={styles.documentsContainer}>
            {filteredDocuments.length === 0 && <span>No records found</span>}
            {filteredDocuments.length > 1 && (
              <Table
                headerData={[
                  "no",
                  "document name",
                  "date of assent",
                  "date of upload",
                  "status",
                  "action",
                ]}
                columnStyles={columnStyles}
              >
                <TableBody>
                  {filteredDocuments.map((item, index) => {
                    return (
                      <TableRow
                        rowStyles={{ height: 60 }}
                        key={index}
                        rowData={tableRowData(item, index)}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
        </>
      )}
      {lastPage && pageCount && !filterValue && (
        <div
          className={styles.page_pagination}
          style={{
            visibility: !documents ? "hidden" : "initial",
            padding: "20px",
          }}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel={nextLabel}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={prevLabel}
            renderOnZeroPageCount={null}
            activeClassName={styles.active_page}
          />
        </div>
      )}
    </div>
  );
}
