import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { baseUrl } from "../../statics/url";

import Loader from "../../components/loader";
import styles from "./styles.module.scss";

import { loadPDF } from "../../helpers/embedPdf";

const Gazette = () => {
  const { slug } = useParams();

  const [data, setData] = useState({});
  const [fetchingData, setFetchingData] = useState(true);

  const [playIndex, setPlayIndex] = useState(0);
  const audioRef = useRef();

  const handleAudioEnd = () => {
    setPlayIndex((playIndex) => playIndex + 1);
  };

  useEffect(() => {
    if (playIndex === 0) return;

    if (playIndex > data.polly_url.length - 1) {
      audioRef.current.src = data.polly_url[0];
      return;
    }

    audioRef.current.src = data.polly_url[playIndex];
    audioRef.current.play();
  }, [playIndex, data]);

  useEffect(() => {
    (async () => {
      try {
        const token = sessionStorage.getItem("token");

        const config = {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        };

        const request = await fetch(`${baseUrl}/gazettes/${slug}`, config);
        const response = await request.json();

        if (response.data) {
          setData(response.data);
          setFetchingData(false);
        }
      } catch (error) {
        console.warn(error);
      }
    })();
  }, [slug]);

  if (fetchingData) return <Loader />;

  return (
    <div className={styles.publish_container}>
      <h4 className={styles.publish_container_title}>{data.title}</h4>

      <div className={styles.publish}>
        <div className={styles.gazette_info}>
          <div className={styles.edit}>
            {data.stage !== 5 && (
              <Link to={`/new?tab=${data.stage}&slug=${data.slug}`}>
                <button style={{ padding: "8px 40px" }}>EDIT</button>
              </Link>
            )}
          </div>

          <h4>{data.title}</h4>

          <span className={styles.gazette_date}>
            Publication Date {data.published_date && data.published_date}
          </span>

          <span className={styles.gazette_senate}>
            Assented by {data.president && data.president.name}
          </span>

          {/* <div className={styles.gazette_tags}>
          <div className={styles.tags}>
            <span>Tags: </span>
            {data.tags?.map((tag, idx) => {
              return (
                <span key={idx} className={styles.tag_box}>
                  {tag.name}
                </span>
              );
            })}
          </div>
        </div> */}

          <div className={styles.gazette_summary}>
            <h5>Publication Summary</h5>
            <p>{data.abstract}</p>
          </div>

          <div className={styles.audio}>
            <audio ref={audioRef} controls onEnded={handleAudioEnd}>
              <source src={data.polly_url[playIndex]} type="audio/mpeg" />
            </audio>
          </div>
        </div>

        <div className={styles.gazette_document}>
          <div className={styles.gazette_document_tabs}>
            <div>
              <p>Original PDF</p>
            </div>

            {/* <div>
            {data.stage !== 5 && (
              <Link to={`/new?tab=${data.stage}&slug=${data.slug}`}>
                <button style={{ padding: "8px 20px" }}>Edit</button>
              </Link>
            )}
          </div> */}
          </div>

          <div
            className={styles.gazette_file}
            id="pdf-div"
            onDocumentLoad={loadPDF({ url: data.file_url, id: "pdf-div" })}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Gazette;
