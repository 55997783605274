import { useEffect, useState, useCallback } from "react";
import dayjs from "dayjs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

import styles from "./styles.module.scss";
import Loader from "../../components/loader";

import { Request } from "../../helpers/requests";
import { baseUrl } from "../../statics/url";

import ReactPaginate from "react-paginate";

import prev_icon from "../../assets/prev_page.svg";
import next_icon from "../../assets/next_page.svg";

const nextLabel = <img src={next_icon} alt="next" />;
const prevLabel = <img src={prev_icon} alt="prev" />;

export default function Users() {
  const [users, setUsers] = useState(null);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);

  const fetchData = useCallback(async () => {
    setUsers(null);

    try {
      const token = sessionStorage.getItem("token");

      const config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const request = await fetch(`${baseUrl}/users?page=${page}`, config);
      const response = await request.json();

      console.log(response);

      if (response.data?.length > 0) {
        setUsers(response.data);
        setPageCount(response.meta.last_page);
      }
    } catch (error) {
      return { error };
    }
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const Delete = async (item) => {
    const token = sessionStorage.getItem("token");

    confirmAlert({
      title: `Delete ${item.first_name} ${item.last_name}?`,
      message: "Are you sure you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setUsers(null);
            const response = await fetch(`${baseUrl}/delete-user/${item.id}`, {
              method: "DELETE",
              headers: {
                Authorization: `bearer ${token}`,
              },
            });

            await response.json();
            const request = new Request("users");
            request.fetchData(setUsers);
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  const Deactivate = async (item) => {
    const token = sessionStorage.getItem("token");

    confirmAlert({
      title: `Deactivate ${item.first_name} ${item.last_name}?`,
      message: "Are you sure you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setUsers(null);
            const response = await fetch(
              `${baseUrl}/deactivate-account/${item.id}`,
              {
                // method: "DELETE",
                headers: {
                  Authorization: `bearer ${token}`,
                },
              }
            );

            await response.json();
            const request = new Request("users");
            request.fetchData(setUsers);
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  const Activate = async (item) => {
    const token = sessionStorage.getItem("token");

    confirmAlert({
      title: `Activate ${item.first_name} ${item.last_name}?`,
      message: "Are you sure you want to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setUsers(null);
            const response = await fetch(
              `${baseUrl}/activate-account/${item.id}`,
              {
                // method: "DELETE",
                headers: {
                  Authorization: `bearer ${token}`,
                },
              }
            );

            await response.json();
            const request = new Request("users");
            request.fetchData(setUsers);
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  return (
    <div className={styles.documents}>
      <div className={styles.documentsHeader}>
        <h3>USERS</h3>
      </div>

      {!users && <Loader />}

      {users && (
        <div className={styles.documentsContainer}>
          {users.length === 0 && <span>No records found</span>}
          {users.length > 0 && (
            <div className={styles.documentsContainerTable}>
              <table>
                <tr>
                  <th>NO</th>
                  <th>USERS</th>
                  <th>DATE JOINED</th>
                  <th>STATUS</th>
                  <th>ACTIONS</th>
                </tr>

                {users &&
                  users.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>
                          {`${item.first_name} ${item.first_name}`} <br />{" "}
                          {item.email}
                        </td>
                        <td>{dayjs(item.created_at).format("DD-MM-YY")}</td>
                        <td>
                          {item.is_active === 1 ? "Active" : "Deactivated"}
                        </td>
                        {item.is_active === 1 && (
                          <td>
                            <button onClick={() => Deactivate(item)}>
                              DEACTIVATE
                            </button>
                            <button onClick={() => Delete(item)}>DELETE</button>
                          </td>
                        )}

                        {item.is_active === 0 && (
                          <td>
                            <button
                              className={styles.activate}
                              onClick={() => Activate(item)}
                            >
                              REACTIVATE
                            </button>
                            <button onClick={() => Delete(item)}>DELETE</button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </table>
            </div>
          )}
        </div>
      )}

      {pageCount && (
        <div
          className={styles.page_pagination}
          style={{ visibility: !users ? "hidden" : "initial" }}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel={nextLabel}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={prevLabel}
            renderOnZeroPageCount={null}
            // pageClassName="page-item"
            // pageLinkClassName="page-link"
            // previousClassName="page-item"
            // previousLinkClassName="page-link"
            // nextClassName="page-item"
            // nextLinkClassName="page-link"
            // breakClassName="page-item"
            // breakLinkClassName="page-link"
            // containerClassName="pagination"
            activeClassName={styles.active_page}
          />
        </div>
      )}
    </div>
  );
}
