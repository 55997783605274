import pages from "../pages";

const routes = [
  {
    path: "/",
    element: <pages.Dashboard />,
    type: "authenticated",
  },
  {
    path: "/documents",
    element: <pages.Document />,
    type: "authenticated",
  },
  {
    path: "/new",
    element: <pages.NewDocument />,
    type: "authenticated",
  },
  {
    path: "/admins",
    element: <pages.Admins />,
    type: "authenticated",
  },
  // {
  //   path: "/tags",
  //   element: <pages.Tags />,
  //   type: "authenticated",
  // },
  {
    path: "/users",
    element: <pages.Users />,
    type: "authenticated",
  },
  {
    path: "/activities",
    element: <pages.Activity />,
    type: "authenticated",
  },
  {
    path: "/gazettes/:slug",
    element: <pages.Gazette />,
    type: "authenticated",
  },
  {
    path: "/categories",
    element: <pages.Categories />,
    type: "authenticated",
  },
  {
    path: "/leaders",
    element: <pages.Leaders />,
    type: "authenticated",
  },
  {
    path: "/search",
    element: <pages.Search />,
    type: "authenticated",
  },
  {
    path: "/my-account",
    element: <pages.Profile />,
    type: "authenticated",
  },
  {
    path: "/signin",
    element: <pages.Signin />,
    type: "protected",
  },
  {
    path: "/my-account",
    element: <pages.Dashboard />,
    type: "authenticated",
  },
];

export default routes;
