import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import dayjs from "dayjs";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

import styles from "./styles.module.scss";
import Loader from "../../components/loader";
import Close from "../../assets/close.svg";

import { Request } from "../../helpers/requests";

import { baseUrl } from "../../statics/url";

export default function Categories() {
  const [categories, setCategories] = useState(null);
  const [addCategories, setAddCategories] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);

  console.log(categories);

  useEffect(() => {
    const request = new Request("categories");
    request.fetchData(setCategories);
  }, []);

  const Delete = async (item) => {
    const token = sessionStorage.getItem("token");

    confirmAlert({
      title: `Delete ${item.title} Category?`,
      message:
        "Are you sure you want to proceed? Once deleted, it cannot be restored",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setCategories(null);
            const response = await fetch(`${baseUrl}/categories/${item.id}`, {
              method: "DELETE",
              headers: {
                Authorization: `bearer ${token}`,
              },
            });

            if (!response.success) return toast.error(response.message);

            await response.json();
            const request = new Request("categories");
            request.fetchData(setCategories);
          },
        },
        {
          label: "No",
          onClick: () => {
            return null;
          },
        },
      ],
    });
  };

  return (
    <div className={styles.documents}>
      {addCategories && (
        <AddCategoryModal
          setAddCategories={setAddCategories}
          setCategories={setCategories}
          categories={categories}
        />
      )}

      {selectedCategory && (
        <EditCategoryModal
          setCategories={setCategories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      )}

      <div className={styles.documentsHeader}>
        <h3>CATEGORIES</h3>
        <button onClick={() => setAddCategories(true)}>NEW CATEGORY</button>
      </div>

      {!categories && <Loader />}

      {categories && (
        <div className={styles.documentsContainer}>
          {categories.length === 0 && <span>No records found</span>}

          {categories.length > 0 && (
            <div className={styles.documentsContainerTable}>
              <table>
                <tr>
                  <th>NO</th>
                  <th>CATEGORIES</th>
                  <th>DATE CREATED</th>
                  <th>ACTIONS</th>
                </tr>

                {categories.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{item.title}</td>
                      <td>{dayjs(item.created_at).format("DD MMMM, YYYY")}</td>
                      <td>
                        <button onClick={() => setSelectedCategory(item)}>
                          EDIT
                        </button>
                        <button onClick={() => Delete(item)}>DELETE</button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function AddCategoryModal({
  categories,
  setAddCategories,
  setCategories,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [savingData, setSavingData] = useState(false);

  const saveAdmin = async () => {
    if (!title || !description) {
      return toast.warn("Name and Description are required");
    }
    try {
      setSavingData(true);
      const token = sessionStorage.getItem("token");

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
          description,
        }),
      };

      const request = await fetch(`${baseUrl}/categories`, config);

      const response = await request.json();

      setSavingData(false);

      if (response.success) {
        setCategories([response.data, ...categories]);
        setAddCategories(false);
        return;
      }

      toast.error(response.message);
    } catch (error) {
      console.log(error);
      setSavingData(false);
    }
  };

  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>ADD CATEGORY</h4>
          <img
            src={Close}
            alt="Close Modal"
            onClick={() => setAddCategories(false)}
          />
        </div>

        <div className={styles.share_modal_input}>
          <label>Category name</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <label>Category description</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <div>
            {!savingData ? (
              <button onClick={saveAdmin}>Save</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Saving</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function EditCategoryModal({
  setSelectedCategory,
  selectedCategory,
  setCategories,
}) {
  const [title, setTitle] = useState(selectedCategory.title);
  const [description, setDescription] = useState(selectedCategory.description);

  const [savingData, setSavingData] = useState(false);

  const saveAdmin = async () => {
    try {
      setSavingData(true);
      const token = sessionStorage.getItem("token");

      const config = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title,
          description,
        }),
      };

      const request = await fetch(
        `${baseUrl}/categories/${selectedCategory.id}`,
        config
      );

      const response = await request.json();

      console.log(response.data);
      setSavingData(false);

      if (response.success) {
        setSelectedCategory(null);

        setCategories(null);
        const request = new Request("categories");
        request.fetchData(setCategories);
        return;
      }

      toast.error(response.message);
    } catch (error) {
      console.log(error);
      setSavingData(false);
    }
  };

  return (
    <div className={styles.share}>
      <div className={styles.share_modal}>
        <div className={styles.share_modal_header}>
          <h4>EDIT CATEGORY</h4>
          <img
            src={Close}
            alt="Close Modal"
            onClick={() => setSelectedCategory(null)}
          />
        </div>

        <div className={styles.share_modal_input}>
          <label>Category name</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <label>Category description</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <div>
            {!savingData ? (
              <button onClick={saveAdmin}>Save</button>
            ) : (
              <button style={{ opacity: 0.5 }}>Saving</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
