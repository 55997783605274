import {
  useState,
  useEffect,
  createContext,
  useContext,
  // useCallback,
} from "react";
// import useSWR from "swr";
// import { baseUrl } from "../config";

// import { baseUrl } from "../config";

export const UserContext = createContext();

export const User = () => {
  const [user, setUser] = useContext(UserContext);
  return { user, setUser };
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userString = sessionStorage.getItem("user");

    if (userString) {
      const userJSON = JSON.parse(userString);
      setUser(userJSON);
    }
  }, []);

  // const handleSWRData = async (data) => {
  //   try {
  //     const userData = sessionStorage.getItem("user");

  //     if (userData) {
  //       if (user) return;

  //       return setUser(userData);
  //     }

  //     const res = await data.json();
  //     setUser(res.data);
  //     sessionStorage.setItem("user", JSON.stringify(res.data));
  //   } catch (error) {}
  // };

  // const getRequestConfig = () => {
  //   const token = sessionStorage.getItem("token");

  //   const requestConfig = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Cache-Control": "no-store",
  //     },
  //   };

  //   return requestConfig;
  // };

  // const fetcher = async (url) => fetch(url, getRequestConfig());

  // const { data } = useSWR(`${baseUrl}/user-profile`, fetcher, {
  //   refreshInterval: 3000,
  // });

  // if (data) {
  //   handleSWRData(data);
  // }

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};
