import { useEffect, useState, useCallback } from "react";
import dayjs from "dayjs";

import { User as UserObject } from "../../contexts/user";
import Table, { TableBody, TableRow } from "../../components/table";

import Loader from "../../components/loader";
import { baseUrl } from "../../statics/url";

import styles from "./styles.module.scss";

export default function Profile() {
  const { user } = UserObject();
  const [data, setData] = useState(null);

  const fetchLogs = useCallback(async () => {
    const token = sessionStorage.getItem("token");
    const params = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    };

    const request = await fetch(`${baseUrl}/user-logs`, params);
    const { data } = await request.json();

    setData(data);
  }, []);

  useEffect(() => {
    fetchLogs();
  }, []);

  const columnStyles = [
    {
      width: "10%",
      justifyContent: "center",
    },
    {
      width: "30%",
      padding: "0 10px",
    },
    {
      width: "40%",
    },
    {
      width: "20%",
      justifyContent: "center",
    },
  ];

  const tableRowData = (item, index) => {
    return [
      { text: `${index + 1}`, styles: { width: "10%" } },
      {
        text: `${item.gazette}`,
      },
      {
        text: `${item.summary}`,
      },
      {
        text: `${dayjs(item.created_at).format("DD MMMM, YYYY")}`,
      },
    ];
  };

  if (!data) return <Loader />;

  return (
    <div className={styles.profile}>
      <main>
        <div className={styles.header}>
          <h4>PROFILE</h4>
        </div>

        <div className={styles.profile_main}>
          <div className={styles.profile_info}>
            {/* <img src={User} alt="User" /> */}

            <div className={styles.user_box}>
              <h4>User Information</h4>
              <div className={styles.user_box_inner}>
                <table style={{ border: "1px solid black" }}>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        <b>
                          {user?.first_name} {user?.last_name}
                        </b>{" "}
                      </td>
                    </tr>

                    <tr>
                      <td>Email</td>
                      <td>
                        <b>{user?.email}</b>{" "}
                      </td>
                    </tr>

                    <tr>
                      <td>Designation</td>
                      <td>
                        <b>{user.role.name}</b>
                      </td>
                    </tr>

                    <tr>
                      <td>Date registered</td>
                      <td>{dayjs(user?.created_at).format("DD MMMM YYYY")}</td>
                    </tr>

                    <tr>
                      <td>Permissions</td>
                      <td>
                        {user.permissions.map((permission, index) => {
                          return (
                            <span
                              key={index}
                              style={{
                                marginRight: "15px",
                                display: "block",
                              }}
                            >
                              {permission.name}
                            </span>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className={styles.edit_profile}>
                <button>EDIT</button>
              </div> */}
            </div>
          </div>

          <div
            className={styles.documentsContainer}
            style={{ padding: "20px" }}
          >
            <h5 style={{ marginBottom: "20px" }}>MY ACTIVITIES</h5>
            {data.length === 0 && <span>No records found</span>}
            {data.length > 1 && (
              <>
                <Table
                  headerData={["no", "gazette name", "summary", "date"]}
                  columnStyles={columnStyles}
                >
                  <TableBody>
                    {data.map((item, index) => {
                      return (
                        <TableRow
                          rowStyles={{ height: 60 }}
                          key={index}
                          rowData={tableRowData(item, index)}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
