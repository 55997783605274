import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import { sentenceCase } from "sentence-case";

import Loader from "../loader";

import { baseUrl } from "../../statics/url";
import { User } from "../../contexts/user";

// import download_icon from "../../assets/download.svg";
// import bookmark_icon from "../../assets/bookmark.svg";
// import share_icon from "../../assets/share_icon_white.svg";

import styles from "./styles.module.scss";

export default function Results({ props }) {
  const { setUserModal } = User();

  const { filteredResults, loading } = props;
  const results = filteredResults;

  const [bookmarks, setBookmarks] = useState([]);
  const [keywords] = useState([]);

  const setBookmark = useCallback(
    async (id) => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        sessionStorage.setItem("documentId", id);
        return setUserModal(true);
      }

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gazette_id: id,
        }),
      };

      try {
        const request = await fetch(`${baseUrl}/bookmarks`, config);
        const response = await request.json();

        if (response.success)
          return setBookmarks([...bookmarks, id.toString()]);
      } catch (error) {
        console.warn(error);
      }
    },
    [bookmarks, setUserModal]
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.action === "bookmark") {
      const id = sessionStorage.getItem("documentId");
      return setBookmark(id);
    }
  }, [setBookmark]);

  return (
    <div className={styles.results}>
      {!loading ? (
        <>
          <span className={styles.header}>
            Showing search results{props.query && <b>: {props.query}</b>}
            <br /> <br />
            <i>
              {" "}
              {results.length} of {props.documentCount} Document(s)
            </i>
          </span>

          {results.length > 0 &&
            results.map((result) => {
              return (
                <div key={result.id} className={styles.result}>
                  <Link to={`/gazettes/${result.slug}`}>
                    <div className={styles.result_header}>
                      <h4>
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[...keywords, ...[props.query]]}
                          autoEscape={true}
                          textToHighlight={result.title || ""}
                        />
                      </h4>
                      <span>
                        Publication Date:{" "}
                        {dayjs(result.published).format("DD MMMM, YYYY")}
                      </span>
                    </div>
                    {/* <span className={styles.result_category}>
                      {result.category && (
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[...keywords, ...[props.query]]}
                          autoEscape={true}
                          textToHighlight={result.category || ""}
                        />
                      )}
                    </span> */}
                    <p>
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={[...keywords, ...[props.query]]}
                        autoEscape={true}
                        textToHighlight={
                          result.abstract ? sentenceCase(result.abstract) : ""
                        }
                      />
                    </p>
                  </Link>
                  {/* <div className={styles.action_buttons}>
                    <button style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={download_icon}
                        alt="download icon"
                        style={{
                          marginRight: "7px",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${result.url}`}
                        download
                      >
                        download
                      </a>
                    </button>
                    {!bookmarks.includes(result.id.toString()) ? (
                      <button
                        onClick={() => setBookmark(result.id)}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={bookmark_icon}
                          alt="bookmark icon"
                          style={{
                            marginRight: "7px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        bookmark
                      </button>
                    ) : (
                      <button style={{ opacity: 0.5 }}>bookmarked</button>
                    )}
                    <button
                      onClick={() => setShare(result.slug)}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={share_icon}
                        alt="share icon"
                        style={{
                          marginRight: "7px",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                      share
                    </button>
                  </div> */}
                </div>
              );
            })}

          {results.length === 0 && (
            <div className={styles.no_result}>
              <span>
                There were no results found for your search. Change your search
                term or filters and try again
              </span>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
