import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { Request } from "../../helpers/requests";
import { User } from "../../contexts/user";

import Loader from "../../components/loader";
import Table, { TableBody, TableRow } from "../../components/table";

import styles from "./styles.module.scss";

export default function Dashboard() {
  const { user, setUser } = User();

  const [documents, setDocuments] = useState(null);
  const [pubActivity, setPubActivity] = useState(null);
  const [metrics, setMetrics] = useState({});
  const [dashboardAdmins, setDashboardAdmins] = useState(null);

  const [tab, setTab] = useState("logs");

  const [sortValue, setSortValue] = useState("uploaded");
  const [filterValue, setFilterValue] = useState({
    status: "",
    admins: "",
    date: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [searchYearValue, setSearchYearValue] = useState(2022);

  useEffect(() => {
    const dataRequest = new Request("logs");
    const metricsRequest = new Request("dashboard-matrix");
    const adminsRequest = new Request("get-admins-for-gazettes-search");
    const pubActivity = new Request("dynamic-call");
    let userRequest = new Request("user-profile");

    if (!user) {
      (async () => {
        const response = await userRequest.fetchData();
        setUser(response.data);
        sessionStorage.setItem("user", JSON.stringify(response.data));
      })();
    }

    Promise.all([
      dataRequest.fetchData(setDocuments),
      metricsRequest.fetchData(setMetrics),
      adminsRequest.fetchData(),
      pubActivity.fetchData(setPubActivity),
    ]).then((response) => {
      console.log(response);
      setDashboardAdmins(response[2]);
    });
  }, [user, setUser]);

  const searchYears = [
    1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010,
    2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
  ];

  const columnStyles = [
    {
      width: "10%",
      justifyContent: "center",
    },
    {
      width: "30%",
    },
    {
      width: "40%",
    },
    {
      width: "20%",
      justifyContent: "center",
    },
  ];

  const pubColumnStyles = [
    {
      width: "10%",
      justifyContent: "center",
    },
    {
      width: "20%",
    },
    {
      width: "40%",
    },
    {
      width: "20%",
      justifyContent: "center",
    },
    {
      width: "20%",
      justifyContent: "center",
    },
  ];

  const tableRowData = (item, index) => {
    return [
      { text: `${index + 1}`, styles: { width: "10%" } },
      {
        text: `${item.user.first_name} ${item.user.last_name}`,
      },
      {
        text: `${item.summary}`,
      },
      {
        text: `${dayjs(item.created_at).format("DD MMMM, YYYY")}`,
      },
    ];
  };

  const pubRowData = (item, index) => {
    console.log(item);
    return [
      { text: `${index + 1}`, styles: { width: "10%" } },
      {
        text: item.user
          ? `${item.user.first_name} ${item.user.last_name} `
          : item.ip_address,
      },
      {
        text: `${item.gazette}`,
      },
      {
        text: item.description,
      },
      {
        text: `${dayjs(item.created_at).format("DD MMMM, YYYY")}`,
      },
    ];
  };

  return (
    <div className={styles.documents}>
      <div className={styles.documentsHeader}>
        <h3>DASHBOARD</h3>
      </div>

      {!documents && <Loader />}

      {documents && (
        <div className={styles.documentsContainer}>
          <div className={styles.documentsContainerActions}>
            <div className={styles.actionsHeader}>
              <span>Browse Gazettes</span>
            </div>

            <div className={styles.actionsSort}>
              <div style={{ backgroundColor: "#f0efef", padding: "10px 10px" }}>
                <div className={styles.actionsSortHeader}>
                  <span>Sort by</span>
                </div>

                <select
                  name=""
                  id=""
                  value={sortValue}
                  onChange={(e) => setSortValue(e.target.value)}
                >
                  <option value="uploaded">Uploaded by</option>
                  <option value="year">Year</option>
                  <option value="status">Status</option>
                </select>
              </div>

              <div className={styles.actionsFilter}>
                <span>Filter Results</span>
                {sortValue === "year" && (
                  <select
                    name=""
                    id=""
                    value={searchYearValue}
                    onChange={(e) => {
                      setSearchYearValue(e.target.value);
                      setSearchValue(e.target.value);
                    }}
                  >
                    {searchYears.reverse().map((item, idx) => {
                      return (
                        <option key={idx} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  // <input
                  //   onChange={(e) => {
                  //     if (
                  //       e.target.value < 1999 ||
                  //       e.target.value > new Date().getFullYear()
                  //     ) {
                  //       return;
                  //     }
                  //     setSearchValue(e.target.value);
                  //   }}
                  //   type="number"
                  // />
                )}
                {sortValue === "uploaded" && (
                  <select
                    name=""
                    id=""
                    value={filterValue.admins}
                    onChange={(e) => {
                      setFilterValue({
                        ...filterValue,
                        admins: e.target.value,
                      });
                      setSearchValue(e.target.value);
                    }}
                  >
                    <option value="">Select Admin</option>
                    {dashboardAdmins &&
                      dashboardAdmins.map((admin, idx) => {
                        return (
                          <option
                            key={idx}
                            value={`${admin.first_name} ${admin.last_name}`}
                          >
                            {`${admin.first_name} ${admin.last_name}`}
                          </option>
                        );
                      })}
                  </select>
                )}
                {sortValue === "status" && (
                  <select
                    name=""
                    id=""
                    value={filterValue.status}
                    onChange={(e) => {
                      setFilterValue({
                        ...filterValue,
                        status: e.target.value,
                      });
                      setSearchValue(e.target.value);
                    }}
                  >
                    <option value="">Select Status</option>
                    {[
                      "Gazette uploaded",
                      "Gazette moved to extraction stage",
                      "Gazette transcribed",
                      "Gazette edited",
                      "Gazette published",
                    ].map((item, idx) => {
                      return (
                        <option key={idx} value={item}>
                          {item}
                        </option>
                      );
                    })}
                    {/* {dashboardAdmins &&
                      dashboardAdmins.map((admin, idx) => {
                        return (
                          <option key={idx} value={admin.id}>
                            {admin.first_name}
                          </option>
                        );
                      })} */}
                  </select>
                )}
                <div>
                  <button>Clear Filter</button>{" "}
                  <button>
                    <Link
                      to={`/documents?filter=${searchValue}&type=${sortValue}`}
                    >
                      Apply Filter
                    </Link>
                  </button>
                </div>
              </div>
            </div>

            <div className={styles.actionsMetrics}>
              <Link to="/users">
                <div>
                  <span>Total Users</span>
                  <span>{metrics.users}</span>
                </div>
              </Link>

              <Link to="/activities">
                <div>
                  <span>Activities</span>
                  <span>{metrics.publication_activities}</span>
                </div>
              </Link>
            </div>

            <Link to="/documents">
              <div
                style={{
                  color: "#fff",
                  backgroundColor: "#222",
                  marginTop: 10,
                  height: "70px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Total Gazettes</span>
                <span>{metrics.gazettes}</span>
              </div>
            </Link>
          </div>

          <div className={styles.tabs_table}>
            <div className={styles.tabs}>
              <button
                style={{
                  color: tab === "logs" ? "green" : "#2222229f",
                  fontWeight: tab === "logs" ? "700" : null,
                }}
                onClick={() => setTab("logs")}
              >
                Recent Activity
              </button>
              <button
                style={{
                  color: tab === "publication" ? "green" : "#2222229f",
                  fontWeight: tab === "publication" ? "700" : null,
                }}
                onClick={() => setTab("publication")}
              >
                Publication Activity
              </button>
            </div>

            {tab === "logs" ? (
              <Table
                headerData={[
                  "no",
                  "user",
                  "activity description",
                  "date uploaded",
                ]}
                columnStyles={columnStyles}
                tableStyles={{ marginLeft: 20 }}
              >
                <TableBody>
                  {documents.map((item, index) => {
                    return (
                      <TableRow
                        rowStyles={{ height: 60 }}
                        key={index}
                        rowData={tableRowData(item, index)}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Table
                headerData={[
                  "no",
                  "user",
                  "gazette name",
                  "description",
                  "date",
                ]}
                columnStyles={pubColumnStyles}
                tableStyles={{ marginLeft: 20 }}
              >
                <TableBody>
                  {pubActivity.map((item, index) => {
                    return (
                      <TableRow
                        rowStyles={{ height: 60 }}
                        key={index}
                        rowData={pubRowData(item, index)}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
