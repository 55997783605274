import React from "react";
import Navbar from "../navbar";
import Sidebar from "../sidebar";

export default function PageWrapper({ children }) {
  return (
    <>
      <header>
        <Navbar />
      </header>
      {children}
      <aside>
        <Sidebar />
      </aside>
    </>
  );
}
