import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import sidebarLinks from "../../statics/sidebar";
import { baseUrl } from "../../statics/url";
import { User } from "../../contexts/user";

import styles from "./styles.module.scss";

export default function Sidebar() {
  const { user } = User();
  const handleLogout = async () => {
    const token = sessionStorage.getItem("token");
    toast.success("Logging out");

    try {
      const request = await fetch(`${baseUrl}/logout`, {
        method: "POST",
        headers: {
          Authorization: `bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
        }),
      });

      const response = await request.json();
      if (response.success) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");

        window.location.replace("https://beta.gazettes.ng");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.sidebar}>
      <ul className={styles.sidebarLinks}>
        <a href="https://beta.gazettes.ng" target="_blank" rel="noreferrer">
          <li className={styles.webLink}>VISIT WEBSITE</li>
        </a>

        {sidebarLinks.map((link, index) => {
          if (user?.role.name !== "SuperAdmin" && link.role === "SuperAdmin") {
            return null;
          }

          return (
            <NavLink
              className={(navigationData) =>
                navigationData.isActive ? styles.active : null
              }
              to={`/${link.link}`}
              key={index}
            >
              <li>{link.name.toUpperCase()}</li>
            </NavLink>
          );
        })}

        <li
          className={styles.logout}
          onClick={handleLogout}
          style={{ cursor: "pointer", color: "#fff" }}
        >
          LOG OUT
        </li>

        {/* <div>
          <button
            style={{
              marginTop: "20px",
            }}
          >
            LOG OUT
          </button>
        </div> */}
      </ul>
    </div>
  );
}
