import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactPaginate from "react-paginate";

import Table, { TableBody, TableRow } from "../../components/table";
import { baseUrl } from "../../statics/url";
import Loader from "../../components/loader";

import styles from "./styles.module.scss";

import prev_icon from "../../assets/prev_page.svg";
import next_icon from "../../assets/next_page.svg";

const nextLabel = <img src={next_icon} alt="next" />;
const prevLabel = <img src={prev_icon} alt="prev" />;

export default function Documents() {
  const [documents, setDocuments] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [filteredDocuments, setFilteredDocuments] = useState(null);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [tab, setTab] = useState("logs");

  const filterDocuments = useCallback(() => {
    if (!documents || !filterValue) {
      setFilteredDocuments(documents);
      return;
    }

    const filteredDocs = documents.filter((doc) => {
      if (
        doc.stage_message.toLowerCase() === filterValue.toLowerCase() ||
        `${doc.user?.first_name.toLowerCase()} ${doc.user?.last_name.toLowerCase()}` ===
          filterValue.toLowerCase() ||
        doc.year.toString() === filterValue
      ) {
        return true;
      } else {
        return false;
      }
    });

    setFilteredDocuments(filteredDocs);
  }, [documents, filterValue]);

  const fetchData = useCallback(async () => {
    setDocuments(null);
    setFilteredDocuments(null);

    try {
      const token = sessionStorage.getItem("token");

      const config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const request = await fetch(`${baseUrl}/${tab}?page=${page}`, config);
      const response = await request.json();

      if (response.data?.length > 0) {
        setDocuments(response.data);

        if (tab !== "logs") {
          return setPageCount(response.last_page);
        }
        setPageCount(response.meta.last_page);
      } else {
        setDocuments([]);
      }
    } catch (error) {
      return { error };
    }
  }, [tab, page]);

  useEffect(() => {
    fetchData();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.filter && setFilterValue(params.filter);
  }, [fetchData]);

  useEffect(() => {
    filterDocuments();
  }, [filterValue, filterDocuments, documents]);

  const columnStyles = [
    {
      width: "10%",
      justifyContent: "center",
    },
    {
      width: "30%",
    },
    {
      width: "40%",
    },
    {
      width: "20%",
    },
    {
      width: "20%",
      justifyContent: "center",
    },
  ];

  const pubRowData = (item, index) => {
    return [
      { text: `${index + 1}`, styles: { width: "10%" } },
      {
        text: item.user
          ? `${item.user.first_name} ${item.user.last_name}`
          : item.ip_address,
      },
      {
        text: `${item.gazette}`,
      },
      {
        text: `${item.description}`,
      },
      {
        text: `${dayjs(item.created_at).format("DD MMMM, YYYY")}`,
      },
    ];
  };

  const tableRowData = (item, index) => {
    return [
      { text: `${index + 1}`, styles: { width: "10%" } },
      {
        text: `hello`,
        text: item.user ? `${item.user.first_name} ${item.user.last_name}` : "",
      },
      {
        text: `${item.summary}`,
      },
      {
        text: `${dayjs(item.created_at).format("DD MMMM, YYYY")}`,
      },
    ];
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  return (
    <div className={styles.documents}>
      <div className={styles.documentsHeader}>
        <h3>ACTIVITIES</h3>
        {/* <Link to="/new">
          <button>New Gazette</button>
        </Link> */}
      </div>

      {!filteredDocuments && <Loader />}

      {filteredDocuments && (
        <>
          <div
            // style={{ backgroundColor: "red" }}
            className={styles.profile_pagination}
          >
            <button
              style={{
                color: tab === "logs" ? "green" : "#2222229f",
                fontWeight: tab === "logs" ? "700" : null,
              }}
              onClick={() => setTab("logs")}
            >
              Recent Activity
            </button>

            <button
              style={{
                color: tab === "dynamic-call" ? "green" : "#2222229f",
                fontWeight: tab === "dynamic-call" ? "700" : null,
              }}
              onClick={() => setTab("dynamic-call")}
            >
              Publication Activity
            </button>
          </div>

          <div className={styles.documentsContainer}>
            {filteredDocuments.length === 0 && <span>No records found</span>}
            {filteredDocuments.length > 1 && (
              <>
                {tab === "logs" && (
                  <Table
                    headerData={[
                      "no",
                      "user",
                      "activity description",
                      "date uploaded",
                    ]}
                    columnStyles={columnStyles}
                  >
                    <TableBody>
                      {filteredDocuments.map((item, index) => {
                        return (
                          <TableRow
                            rowStyles={{ height: 60 }}
                            key={index}
                            rowData={tableRowData(item, index)}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                )}

                {filterDocuments && tab === "dynamic-call" && (
                  <Table
                    headerData={[
                      "no",
                      "user",
                      "gazette name",
                      "description",
                      "date",
                    ]}
                    columnStyles={columnStyles}
                    tableStyles={{ marginLeft: 20 }}
                  >
                    <TableBody>
                      {filteredDocuments.map((item, index) => {
                        return (
                          <TableRow
                            rowStyles={{ height: 60 }}
                            key={index}
                            rowData={pubRowData(item, index)}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </>
            )}
          </div>
        </>
      )}
      {pageCount && (
        <div
          className={styles.page_pagination}
          style={{ visibility: !filteredDocuments ? "hidden" : "initial" }}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel={nextLabel}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={prevLabel}
            renderOnZeroPageCount={null}
            // pageClassName="page-item"
            // pageLinkClassName="page-link"
            // previousClassName="page-item"
            // previousLinkClassName="page-link"
            // nextClassName="page-item"
            // nextLinkClassName="page-link"
            // breakClassName="page-item"
            // breakLinkClassName="page-link"
            // containerClassName="pagination"
            activeClassName={styles.active_page}
          />
        </div>
      )}
    </div>
  );
}
