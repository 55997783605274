import React, { useState, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";

import "./styles.scss";

const TagBox = ({ tags, selectedTags, setSelectedTags }) => {
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  const focus = () => {
    inputRef.current.focus();
  };

  return (
    <div
      className="new-story__publish--publish-info__tags-container"
      onClick={focus}
    >
      <div className="new-story__publish--publish-info__tags">
        {selectedTags.map((tag, index) => (
          <div key={index}>
            <span>{tag}</span>{" "}
            <AiOutlineClose
              onClick={() => {
                setSelectedTags([
                  ...selectedTags.splice(0, index),
                  ...selectedTags.splice(index + 1),
                ]);
              }}
            />
          </div>
        ))}
      </div>

      <input
        ref={inputRef}
        type="text"
        list="tags"
        className="new-story__publish--publish-info__tag-input"
        value={input}
        placeholder="Add a tag"
        onChange={(e) => {
          setInput(e.target.value);
        }}
        style={{ display: selectedTags.length < 5 ? "initial" : "none" }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            const allowedValue = tags.find(
              (item) => e.target.value.toLowerCase() === item.name.toLowerCase()
            );
            if (allowedValue)
              setSelectedTags([...selectedTags, e.target.value]);
            setInput("");
          }
        }}
      />

      <datalist id="tags">
        {tags.map((tag, idx) => {
          return <option key={idx} value={tag.name} />;
        })}
      </datalist>
    </div>
  );
};

export default TagBox;
