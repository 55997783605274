import React, { useState, useEffect } from "react";

import { Request } from "../../helpers/requests";

import styles from "./styles.module.scss";

export default function Filters({ props, showFilters, setShowFilters }) {
  const years = [
    1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010,
    2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
  ];

  const [tags, setTags] = useState([]);
  const [dbcategories, setDbCategories] = useState([]);

  const updateYears = (e) => {
    const { filters } = props;
    const { years, setYears } = filters;

    if (e.target.checked) {
      setYears([...years, e.target.name]);
      return;
    }

    const index = years.indexOf(e.target.name);
    setYears([...years.splice(0, index), ...years.splice(index + 1)]);
  };

  const updateCategories = (e) => {
    const { filters } = props;
    const { categories, setCategories } = filters;

    if (e.target.checked) {
      setCategories([...categories, e.target.name]);
      return;
    }

    const index = categories.indexOf(e.target.name);
    setCategories([
      ...categories.splice(0, index),
      ...categories.splice(index + 1),
    ]);
  };

  const updateTags = (tag) => {
    const { filters } = props;
    const { tags, setTags } = filters;

    const index = tags.indexOf(tag.name);

    if (index === -1) {
      setTags([...tags, tag.name]);
      return;
    }

    setTags([...tags.splice(0, index), ...tags.splice(index + 1)]);
  };

  if (window.innerWidth < 1200) setShowFilters(false);

  useEffect(() => {
    (async () => {
      const tagsRequest = new Request("tags");
      const categoriesRequest = new Request("categories");

      Promise.all([
        tagsRequest.fetchData(setTags),
        categoriesRequest.fetchData(setDbCategories),
      ]);
    })();
  }, []);

  return (
    <div className={styles.filters}>
      <div className={styles.toggle}>
        <button
          style={{ backgroundColor: showFilters ? "red" : "green" }}
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? "Hide" : "Show"} Filters
        </button>
      </div>

      {showFilters && (
        <>
          <div className={styles.header}>
            <h3>Filter</h3>
            <button
              onClick={() => {
                props.filters.setYears([]);
                props.filters.setCategories([]);
              }}
            >
              clear all
            </button>
          </div>

          <div className={styles.categories}>
            <h3>Categories</h3>

            <div>
              {dbcategories.map((category) => {
                return (
                  <React.Fragment key={category.title}>
                    <input
                      type="checkbox"
                      id={category.title}
                      name={category.title}
                      onChange={(e) => {
                        updateCategories(e);
                      }}
                      checked={props.filters.categories.includes(
                        category.title
                      )}
                    />
                    <label htmlFor={category.title}>{category.title}</label>
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          <div className={styles.date}>
            <h3>Publication Date</h3>

            <div>
              {years.reverse().map((year) => {
                return (
                  <div key={year}>
                    <input
                      type="checkbox"
                      id={year}
                      name={year}
                      onChange={(e) => updateYears(e)}
                      checked={props.filters.years.includes(year.toString())}
                    />
                    <label htmlFor={year}>{year}</label>
                    <br />
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className={styles.tags}>
            <h3>Tags</h3>

            <div>
              {tags.map((tag) => {
                return (
                  <React.Fragment key={tag.name}>
                    <button
                      style={{
                        backgroundColor:
                          props.filters.tags.includes(tag.name) && "#129017",
                        color: props.filters.tags.includes(tag.name) && "#fff",
                      }}
                      onClick={() => updateTags(tag)}
                    >
                      {tag.name}
                    </button>
                  </React.Fragment>
                );
              })}
            </div>
          </div> */}

          <div className={styles.volume}>
            <h3>Volume no.</h3>

            <div>
              <select name="" id="">
                <option value="">Select a Volume no.</option>
                {/* <option value=""></option> */}
              </select>
            </div>
          </div>

          <div className={styles.filter_button}>
            <button onClick={props.filterResults}>Filter</button>
          </div>
        </>
      )}
    </div>
  );
}
