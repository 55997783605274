import { useEffect, useState } from "react";
import Loader from "../components/loader";
import PageWrapper from "../components/wrappers/pageWrappers";
import { baseUrl } from "../statics/url";

export default function AuthRoute({ children }) {
  const [authCheck, setAuthCheck] = useState(true);

  // if (!userSession) return window.location.replace("https://beta.gazettes.ng");

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      return setAuthCheck(false);
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { admrdrt } = params;

    if (admrdrt) {
      (async () => {
        try {
          const options = {
            method: "GET",
            headers: { Authorization: `Bearer ${admrdrt}` },
          };

          const request = await fetch(`${baseUrl}/user-profile`, options);

          // const response = await request.json();

          if (request.status === 200) {
            // sessionStorage.setItem("user", JSON.stringify(response.data));
            sessionStorage.setItem("token", admrdrt);
          } else {
            return window.location.replace("https://beta.gazettes.ng");
          }
          setAuthCheck(false);
        } catch (error) {
          console.log(error);
          return window.location.replace("https://beta.gazettes.ng");
        }
      })();
    } else {
      return window.location.replace("https://beta.gazettes.ng");
    }
  }, []);

  if (authCheck) return <Loader />;

  return <PageWrapper>{children}</PageWrapper>;
}
